// To understand this, read this: https://medium.com/hackernoon/finally-the-typescript-redux-hooks-events-blog-you-were-looking-for-c4663d823b01

export function createActions<M extends { [index: string]: any }>() {
  return <Key extends keyof M>(
    name: Key,
    ...args: M[Key] extends undefined ? [] : [M[Key]]
  ) => {
    return args.length > 0 ? { type: name, payload: args[0] } : { type: name }
  }
}

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}
