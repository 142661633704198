/**
 * Returns an array with the `key` added or removed from the input array.
 * @param array Array of keys to search for
 * @param key The value to match against array
 */
export function toggleItemInEnumArray<T>(array: T[], key: T) {
  const newArray = array.slice()
  return array.find((x) => x === key)
    ? newArray.filter((x) => x !== key)
    : [...newArray, key]
}

export function toggleItemsInEnumArray<T>(array: T[], keys: T[]) {
  const newArray = array.slice()
  keys.forEach((val) => {
    toggleItemInEnumArray(newArray, val)
  })
  return newArray
}

export function updateCollectionItemByID<T extends { id: number | string }>(
  array: T[],
  id: number | string,
  newObj: T
): T[] {
  const itemToUpdate = array.find((item) => item.id === id)
  const updatedObj = { ...itemToUpdate, ...newObj }
  return [...array.slice().filter((item) => item.id !== id), updatedObj]
}

export function updateCollectionItemByIndex<T extends { id: number | string }>(
  array: T[],
  idOrKey: number | string,
  newObj: T,
  keyName?: string
): T[] {
  let itemIndexToUpdate = array.findIndex((item) => item.id === idOrKey)
  if (itemIndexToUpdate === -1 && keyName) {
    itemIndexToUpdate = array.findIndex((item) => item[keyName] === idOrKey)
  }
  const updatedObj = { ...array[itemIndexToUpdate], ...newObj }
  return [
    ...array.slice(0, itemIndexToUpdate),
    updatedObj,
    ...array.slice(itemIndexToUpdate + 1),
  ]
}

export const arrayUtils = {
  toggleItemInEnumArray,
  updateCollectionItemByID,
  updateCollectionItemByIndex,
}
