// FirebaseProvider.tsx
import React, {
  createContext,
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react"
import { initializeApp, FirebaseApp } from "firebase/app"
import { getFirestore, Firestore } from "firebase/firestore"
import {
  getFunctions,
  connectFunctionsEmulator,
  Functions,
} from "firebase/functions"
import {
  getDatabase,
  connectDatabaseEmulator,
  Database,
} from "firebase/database"
import {
  FirebaseStorage,
  connectStorageEmulator,
  getStorage,
} from "firebase/storage"
import {
  connectAuthEmulator,
  getAuth,
  Auth,
  signInAnonymously,
} from "firebase/auth"
import {
  AppCheck,
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check"
import {
  getVertexAI,
  getGenerativeModel,
  VertexAI,
  GenerativeModel,
  HarmCategory,
  HarmBlockThreshold,
  HarmBlockMethod,
} from "firebase/vertexai-preview"

// Your config that you stored in the env file.
const recaptchaEnterpriseSiteKey = `${process.env.GATSBY_RECAPTCHA_ENTERPRISE_SITE_KEY}`

const firebaseConfigDefault = {
  apiKey: `${process.env.GATSBY_FIREBASE_APIKEY}`,
  appId: `${process.env.GATSBY_FIREBASE_HUB_APPID}`,
  authDomain: `${process.env.GATSBY_FIREBASE_AUTHDOMAIN}`,
  databaseURL: `${process.env.GATSBY_FIREBASE_DATABASEURL}`,
  measurementId: `${process.env.GATSBY_FIREBASE_MEASUREMENTID}`,
  messagingSenderId: `${process.env.GATSBY_FIREBASE_MESSAGINGSENDERID}`,
  projectId: `${process.env.GATSBY_FIREBASE_PROJECTID}`,
  storageBucket: `${process.env.GATSBY_FIREBASE_HUB_STORAGEBUCKET}`,
}

const firebaseConfigPlatformHealth = {
  ...firebaseConfigDefault,
  databaseURL: `${process.env.GATSBY_FIREBASE_DATABASE_PLATFORM_HEALTH_URL}`,
}

// The type definition for the firebase context data.
export interface FirebaseContextData {
  isInitialized: boolean
  auth: Auth | null
  firebase: FirebaseApp | null
  db: Firestore | null
  functions: Functions | null
  rtdb: Database | null
  rtdbPlatform: Database | null
  storage: FirebaseStorage | null
  vertexAI: GenerativeModel | null
}

// The firebase context that will store the firebase instance and other useful variables.
const FirebaseContext = createContext<FirebaseContextData>({
  auth: null,
  db: null,
  firebase: null,
  isInitialized: false,
  functions: null,
  rtdb: null,
  rtdbPlatform: null,
  storage: null,
  vertexAI: null,
})

// The provider that will store the logic for manipulating the firebase instance and variables.
const FirebaseProvider: React.FC = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const appRef = useRef<FirebaseApp | null>(null)
  const appPlatformRef = useRef<FirebaseApp | null>(null)
  const authRef = useRef<Auth | null>(null)
  const dbRef = useRef<Firestore | null>(null)
  const fnRef = useRef<Functions | null>(null)
  const rtdbRef = useRef<Database | null>(null)
  const rtdbPlatformRef = useRef<Database | null>(null)
  const storageRef = useRef<FirebaseStorage | null>(null)
  const vertexAIRef = useRef<VertexAI | null>(null)
  const vertexAIModelRef = useRef<GenerativeModel | null>(null)

  // If firebase has not been initialized then initialize it.
  if (typeof window !== "undefined" && appRef.current === null) {
    appRef.current = initializeApp(firebaseConfigDefault)
    appPlatformRef.current = initializeApp(
      firebaseConfigPlatformHealth,
      "platform"
    )
    authRef.current = getAuth(appRef.current)
    dbRef.current = getFirestore(appRef.current)
    fnRef.current = getFunctions(appRef.current, "europe-west3")
    rtdbRef.current = getDatabase(appRef.current)
    rtdbPlatformRef.current = getDatabase(appPlatformRef.current)
    storageRef.current = getStorage(appRef.current)

    initializeAppCheck(appRef.current, {
      provider: new ReCaptchaEnterpriseProvider(recaptchaEnterpriseSiteKey),
      isTokenAutoRefreshEnabled: true,
    })

    vertexAIRef.current = getVertexAI(appRef.current, {
      location: "europe-west3",
    })
    vertexAIModelRef.current = getGenerativeModel(vertexAIRef.current, {
      model: "gemini-1.5-flash",
      safetySettings: [
        {
          category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
          threshold: HarmBlockThreshold.BLOCK_NONE,
          method: HarmBlockMethod.PROBABILITY,
        },
      ],
    })

    if (process.env.GATSBY_ACTIVE_ENV === "dev") {
      /* connectAuthEmulator(authRef.current, "http://localhost:9099", {
        disableWarnings: true,
      }) */
      connectFunctionsEmulator(fnRef.current, "localhost", 5001)
      connectDatabaseEmulator(rtdbPlatformRef.current, "localhost", 9000)
      // connectStorageEmulator(storageRef.current, "localhost", 9199)
    }
    setIsInitialized(true)
  }

  useEffect(() => {
    const auth = getAuth()
    signInAnonymously(auth).then(() => {
      // Signed in..
    })
  }, [])

  return (
    <FirebaseContext.Provider
      value={{
        auth: authRef.current,
        firebase: appRef.current,
        db: dbRef.current,
        isInitialized,
        functions: fnRef.current,
        rtdb: rtdbRef.current,
        rtdbPlatform: rtdbPlatformRef.current,
        storage: storageRef.current,
        vertexAI: vertexAIModelRef.current,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  )
}

export { FirebaseContext, FirebaseProvider }
